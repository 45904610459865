<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >special provisions</label
            >
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >utilities/services paid by owner</label
          >

          <div class="form-row flex-wrap">
            <div class="form-row">
              <CheckboxInputSmall type="checkbox" name="utilities.gas" />
              <div class="text-title mr-8">gas</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall type="checkbox" name="utilities.water" />
              <div class="text-title mr-8">water</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="utilities.electricity"
              />
              <div class="text-title mr-8">electricity</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="utilities.trashRecycling"
              />
              <div class="text-title mr-8">trash/recycling</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="utilities.cableSatellite"
              />
              <div class="text-title mr-8">cable/satellite</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="utilities.masterAntenna"
              />
              <div class="text-title mr-8">master antenna</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall type="checkbox" name="utilities.internet" />
              <div class="text-title mr-8">internet</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="utilities.stormwaterDrainage"
              />
              <div class="text-title mr-8">stormwater/drainage</div>
            </div>
            <div class="form-row" style="margin-bottom: 1rem">
              <CheckboxInputSmall
                type="checkbox"
                name="utilities.governmentFees"
              />
              <div class="text-title mr-8">
                government fees (student lease only)
              </div>
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="utilities.other" />
            </button>

            <div class="text-title">other</div>

            <TextField
              name="utilities.otherText"
              class="ml-4"
            />
          </div>
          <div class="form-row">
            <div class="text-title">
              electric provider. you'll be liable for a
            </div>
            <TextInputSmall name="electricProviderCost" type="number" />
            <div class="text-title">
              charge, plus the cost of electricity used...
            </div>
          </div>

          <label class="form-section-sub-title">special provisions</label>

          <div class="form-row">
            <text-input
              id="specialProvisions"
              v-model="specialProvisions"
              rows="5"
              multiline
            />
          </div>

          <div class="h-4"></div>

          <label class="form-section-sub-title">unit comes</label>

          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="unitComesFurnished"
                style="margin-left: 0"
              />
            </button>

            <div class="text-title mr-8">furnished</div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="unitComesNotFurnished"
                style="margin-left: 0"
              />
            </button>

            <div class="text-title mr-8">unfurnished</div>
          </div>

          <div class="h-4"></div>

          <label class="form-section-sub-title">pay rent</label>

          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="payRentAtManagers"
                style="margin-left: 0"
              />
            </button>

            <div class="text-title mr-8">at the on-site manager's office</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="payRentOnlineCheckbox"
                style="margin-left: 0"
              />
            </button>

            <div class="text-title mr-4">
              through our online payment site at
            </div>

            <TextField
              name="payRentOnline"
              style="margin-left: 0"
            />
          </div>

          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >move-out notice will terminate lease</label
          >

          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="moveOutLastDay"
                style="margin-left: 0"
              />
            </button>

            <div class="text-title mr-5">
              on the last day of the month following the next due date
            </div>
            <button>
              <CheckboxInputSmall type="checkbox" name="moveOutExactDay" />
            </button>

            <div class="text-title">
              on the exact day designated in the move-out notice
            </div>
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextField from "@/components/form/TextField";
import TextInputSmall from "@/components/form/TextInputSmall";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import { FinalForm } from "vue-final-form";
import NotifyMixin from "@/mixins/NotifyMixin";
import TextInput from '@/components/ui/TextInput';

export default {
  name: "SpecialProvions",
  components: {
    Loader,
    ModalFooter,
    TextField,
    TextInputSmall,
    CheckboxInputSmall,
    FinalForm,
    TextInput,
  },
  mixins: [ModalNavigation, InitializeFormMixin, NotifyMixin],
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },
      utilities: {
        gas: "",
        water: "",
        electricity: "",
        trashRecycling: "",
        cableSatellite: "",
        masterAntenna: "",
        internet: "",
        stormwaterDrainage: "",
        governmentFees: "",
        other: "",
        otherText: "",
      },
      electricProviderCost: "",
      specialProvisions: "",
      unitComesFurnished: "",
      unitComesNotFurnished: "",
      payRentAtManagers: "",
      payRentOnlineCheckbox: "",
      payRentOnline: "",
      moveOutLastDay: "",
      moveOutExactDay: "",
    };
  },
  computed: {
    initialValues() {
      return {
        utilities: {
          gas: "",
          water: "",
          electricity: "",
          trashRecycling: "",
          cableSatellite: "",
          masterAntenna: "",
          internet: "",
          stormwaterDrainage: "",
          governmentFees: "",
          other: "",
          otherText: "",
        },
        electricProviderCost: "",
        specialProvisions: "",
        unitComesFurnished: "",
        unitComesNotFurnished: "",
        payRentAtManagers: "",
        payRentOnlineCheckbox: "",
        payRentOnline: "",
        moveOutLastDay: "",
        moveOutExactDay: "",
      };
    },
  },
  methods: {
    saveData() {
      this.$refs.form.formState.values.specialProvisions =
        this.specialProvisions;

      if (
        !this.$refs.form.formState.values.unitComesFurnished &&
        !this.$refs.form.formState.values.unitComesNotFurnished
      ) {
        this.notifyError("Please select one checkbox from unit.");
      } else if (
        !this.$refs.form.formState.values.payRentAtManagers &&
        !this.$refs.form.formState.values.payRentOnlineCheckbox
      ) {
        this.notifyError("Please select one checkbox from pay rent.");
      } else if (
        !this.$refs.form.formState.values.moveOutExactDay &&
        !this.$refs.form.formState.values.moveOutLastDay
      ) {
        this.notifyError("Please select one checkbox from move-out.");
      }
    },
  },
};
</script>

<style scoped>
input:checked {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
